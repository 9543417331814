.container {
	background-color: pink;

	.child {
		font-size: 100px;
	}
}

.container2 {
	background-color: blue;
}
.CheckBox {
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.CheckBox .label {
  margin-left: 20px;
  /* border-bottom: 1px solid lightslategray; */
}

.CheckBox input {
  margin: 6px;
}

.csvDown {
  margin: 20px 0 10px 0;
  /* padding: 20px 0 10px 0; */
}

.channelList {
  margin-bottom: 30px;
}
/* ▼▼▼▼▼  Table css ▼▼▼▼▼ */
/* 
.tableContainer {
    padding: 3px;
    background-color: #fff;
}

.tableHeader>tr>th {
    padding: 1px;
    vertical-align: middle;
    background-color: #ddebf7;
    border: 0.5px solid rgb(100, 100, 100);
}

.tableBody>tr>td {
    padding: 1px;
    padding-right: 1rem;
    text-align: right;
    vertical-align: middle;
    border: 0.5px solid rgb(100, 100, 100);
}

.tableFooter {
    font-weight: bold;
    border: 1.5px solid rgb(100, 100, 100);
} */
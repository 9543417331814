.table {
  border: 1px #a39485 solid;
  font-size: .9em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .25);
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;

  thead {
    font-weight: bold;
    color: #fff;

    tr{
      th {
        text-align: center;
        padding: 5px;
        vertical-align: middle;
        background: #FF6671;

        .price_value {
          display: flex;
          flex-direction: row;
          justify-content: center;

          div {
            width: 50%;
          }
        }
      }
      th:nth-child(1) {
        width: 5px;
      }
    }


    // th:nth-child(1) {
    //   // border-right: 1px solid #fff;
    //   // border-bottom: 1px solid #fff;
    //   font-weight: 400;
    //   color: #fff;
    //   background: #FF6671;
    // }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        background: #fff;
        padding: 1em .5em;
        vertical-align: middle;

        .isSubscribeInputBox {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        
        .input_name {
          text-align: center;
          width: 200px;
        }
        .input {
          width: 70px;
        }
        
        .btnBox {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;
        
          .button,
          .isEditingBtn {
            width: 60px;
            height: 28px;
            padding: 5px 12px;
            border: none;
            border-radius: 6px;
        
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
          }
        
          .button {
            background: #FF9B99;
          }
        
          .isEditingBtn {
            background: #FF0045;
          }
        }
      }
      
      td:nth-child(1) {
        width: 5px;
      }
    }

    // tr:nth-child(odd) {
    //   td {
    //     background: #FFD2CC;
    //   }
    // }
  }
}

.brand_container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sub_title {
    // margin: 0 0 10px 8px;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
  }
}
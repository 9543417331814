.container {
  margin-bottom: 50px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #d2d2dc;
  border-radius: 0
}

.header {
  position: relative;
  padding: 0 10px;
  display: flex;
  height: 40px;
}

.title {
  color: #000000;
  margin-bottom: 0.625rem;
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 500
}

.description {
  margin-bottom: .875rem;
  font-weight: 400;
  color: #76838f
}

.upGrade {
  color: #ff004f;
  font-size: 0.6rem;
}

.downGrade {
  color: #1260cc;
  font-size: 0.6rem;
}

.table tr {
  height: 50px;
  line-height: 110%;
}

.table tr:hover {
  border: 1px solid #76838f;
}

.table th, td {
  font-size: 0.875rem !important;
}

.table td {
  cursor: pointer;
}

.table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.title {
  font-size: 30px; font-weight: bold;
}

.title button {
  background-color: white;
  padding: 5px;
}

.title button:hover {
  background-color: #dedede;
}

.selected {
  margin-right: 30px;
  color: black;
  border: 3px black solid;
}

.unselected {
  margin-right: 30px;
  color: #cccccc;
  border: 1px #cccccc solid;
}

.enterprise_container {
  margin: 50px 0;
}

.enterprise_container:first-child {
  margin: 0;
}
.wrapper {
  width: 100%;
  height: 90%;
  padding-top: 42px;
  padding-bottom: 55px;
  background-color: white;
  border-radius: 3px;
  align-self: center;
  font-family: "Noto Sans KR";

  > div {
    background-color: rgb(250, 246, 247);
    overflow-y: auto;
    height: 100%;
  }
}

@import '../../../node_modules/react-datepicker/dist/react-datepicker.css';

//common
.section-line {
  margin: 20px 0px;
  border-color: #CFCDCC;
  text-align: center;
  font-weight: bold;
}

.content-top-action-btn {
  justify-content: flex-end;
  display: flex;
}

.top-stat-wrap {
  margin-bottom: 10px;
}

.top-stat-wrap-table {
  margin-bottom: 10px;
}

.top-stat-wrap-table table {
  border-collapse: collapse;
}

.top-stat-wrap-table table {
  border: 1px solid black;
  border-collapse: collapse;
  width: 100%;
}

.list-pagination-wrap thead,
th,
td {
  text-align: center;
  font-size: 12px;
  width: 200px;
  height: 25px;
}

.display-none {
  display: none;
}

.scrollbar {
  margin-left: 0px;
  float: left;
  max-height: 480px;
  width: 100%;
  background: #F5F5F5;
  overflow-y: scroll;
  margin-bottom: 25px;
  border-radius: 10px;
}

.backoffic-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #B5B3B3;
  background-color: #F5F5F5;
}

.backoffic-scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.backoffic-scrollbar::-webkit-scrollbar-thumb {
  background-color: #B5B3B3;
}


//member
.member-detail-data-wrap {
  text-align: center;
  margin: 3px 0px;
  padding: 20px 5px;
  // border: solid 1px;
  border-color: #9D9C9C;
}

.member-detail-data-title {
  border-bottom: solid 1px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  width: 150px;
  border-color: #9D9C9C;
  padding: 5px 0px;
  font-size: 15px;
}


.member-detail-history-wrap {
  border: solid 1px;
  border-color: #9D9C9C;
  margin-top: 20px;
  background-color: #fff;
  height: 200px;
}

.member-change-expiredate-table,
th td {
  font-size: 16px;
}

.member-change-expiredate-table-data {
  font-size: 16px;
  padding-top: 25px;
}

.member-detail-history-title {
  border-bottom: solid 1px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  background-color: #CFCDCC;
  border-color: #9D9C9C;
  padding-left: 10px 0px;
}

.member-detail-action-btn-area {
  text-align: center;
  margin: 20px 0px;
}

.member-grade-date-picker {
  margin-left: 35px;
}

//HotTrend
.custom-date-picker {
  width: 65% !important;
  height: 100% !important;
}

.hottrend-keyword-box-background {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #ffffff;
  width: 65% !important;
  height: 100%;
  padding: 5px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, .2);
}

.hottrend-keyword-box {
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-color: #b3b3b3;
  border-radius: 4px;
}

.hottrend-keyword-items {
  align-self: center;
  margin: auto;
}

.hottrend-keyword-item {
  margin: 5px;
  color: #96dbfa;
  border: 1px solid transparent;
  border-color: #96dbfa;
  border-radius: 0.25rem;
  align-items: center;
  background: transparent;
  outline: none !important;
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  padding: 0.65rem 1rem;
  font-size: 15px;
  line-height: 1.5;
  transition: color 0.15s;
  text-transform: none;
}

.hottrend-preview-background {
  background-color: #ffffff;
  margin: 50px auto;
  border: 1px solid;
  border-color: #000000;
}

//influencerChannels Add
.influencer-channels-datasheet {
  width: 100%;
  height: 100%;
}

.data-editor {
  height: 100% !important;
}



// 메일 발송
.input-div {
  margin: 10px 0 10px 0;
  width: 800px
}

.title-input {
  width: 300px;
  margin: 0 10px 0 0;
}

.editorClassName {
  background-color: white;
  padding: 0px 10px;
}

.backoffice-send-mail-button {
  margin: 10px 0 10px 0;
}

.input-desc {
  width: 200px;
  margin: 0 10px 0 0;
}

// 토스트 메시지
.Toastify__toast-body {
  font-size: 15px;
  font-weight: 600;
}

.Toastify__toast--success {
  background: #FB7F8F !important;
  border-radius: 5px !important;
}

// 캠페인
.campaign-list-wrap {
  width: 100% !important;
  height: max-content;
  margin-top: 20px;
  margin-bottom: 20px;
}

.campaign-unowned-channels {
  width: 100%;
  height: 100%;
  margin: 20px 0 30px 0;
  align-items: center;
  // background-color: skyblue;
}

// .campaign-?

.campaign-list-datepicker-wrap {
  display: flex;
  width: max-content;
  margin-right: 30px;
  align-items: center;
}

.campaign-list-datepicker-toggle-button-wrap {
  display: flex;
  margin-top: 10px;
  justify-content: start;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker-input {
  width: 100%;
}


// 캠페인 리스트 모달
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: transform 0.5s cubic-bezier(0, 0.52, 0, 1);
}

.modal_content {
  position: relative;
  height: 80%;
  overflow-y: scroll;
  background-color: #fefefe;
  /* white background for the modal */
  // margin: auto; /* center the modal vertically and horizontally */
  padding: 20px;
  border: 1px solid #888;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .campaign_info {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background: #FFD2CC;
    border-radius: 6px;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #8B8B8B;
  }

  .campaignList_previewBtn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .campaignList {
      width: 125px;
      height: 28px;
      padding: 5px;
      text-align: center;
      background: #FFD2CC;
      border-radius: 6px;
      margin: 0 0 10px 0;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: black;
    }

    .previewBtn {
      width: 125px;
      height: 28px;
      padding: 5px 12px;
      gap: 2px;
      margin: 0 0 10px 0;

      background: #FF0045;
      border: none;
      border-radius: 6px;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: #FFFFFF;

      cursor: pointer;
    }
  }

}

.modal_content::-webkit-scrollbar {
  display: none;
}

.channel_container {
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 2px solid hotpink;
  border-radius: 6px;
  gap: 10px;
  padding: 10px 20px 10px 20px;
  margin: 0 0 10px 0;
  position: relative;

  .check_box {
    width: 25px;
    height: 20px;
    position: absolute;
    top: 8px;
    left: 5px;
    // .check {
    //   justify-content: flex-start;
    // }
  }

  .check_state {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 60px;
    height: 25px;
    background: #FF9B99;
    border-radius: 6px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    font-size: 12px;
  }


  .title_container {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFD2CC;
    padding: 10px 20px;
    border-radius: 10px;
    
    .thumbnails_box {
      width: 85px;
      height: 85px;
      border: 2px solid hotpink;
      border-radius: 50%;
  
      .thumbnails {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .info {
      width: 60%;
      border-radius: 6px;
      text-align: center;
      line-height: 25px;
  
      color: #24292E;
      font-size: 12px;
      font-family: Pretendard;
      font-weight: 400;
      font-style: normal;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .registered_mail {
    width: 100%;
    // margin: 0 0 20px 0;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .mail_title {
      text-align: center;
      font-size: 12px;
      margin-bottom: 10px;
    }

    .mail_data {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;

      // input[type="text"]::-webkit-search-cancel-button {
      //   @include clickable();
      // }

      input[type="text"]:focus {
        border: 1px solid #ff0045;
        outline: none;
      }

      .mail_input {
        height: 40px;
        width: 70%;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.6px;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        padding: 9px 10px;
      }

      .mail_input::placeholder {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: lightGrey;
      }

      .mail_btn {
        width: 60px;
        height: 32px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px 12px;
        gap: 2px;

        background: #FF0045;
        border: none;
        border-radius: 6px;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #FFFFFF;

        cursor: pointer;
      }
    }
  }

  .unregistered_mail {
    width: 80%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: 12px;
  }

  .channel_container_btn {
    width: 90%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;

    .btn {
      width: 143px;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 5px 12px;
      gap: 2px;

      background: #FF0045;
      border: none;
      border-radius: 6px;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #FFFFFF;
    }
  }
}

// 광고 제안 미리보기 모달
.ad_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: transform 0.5s cubic-bezier(0, 0.52, 0, 1);
}

.ad_modal_content {
  position: relative;
  height: 615px;
  background-color: #fefefe;
  /* white background for the modal */
  // margin: auto; /* center the modal vertically and horizontally */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 760px;

  .ad_close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .ad_close:hover,
  .ad_close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .contentWhiteBox {
    padding: 30px 30px 0 30px;
    background-color: white;
    border-radius: 6px;

    .tableContent {
      .tableTitle {
        padding: 20px 0;
        font-size: 18px;
        color: #3D3D3D;
        font-weight: 700;
      }

      .tableContainer {
        font-size: 14px;
        color: #3D3D3D;
        width: 100%;

        .table {
          width: 100%;
          border-collapse: collapse;

          td {
            border: 1px solid #DFDFDF;
            border-left: none;
            border-right: none;
          }

          .subTitle {
            width: 25%;
            background-color: #F9F9F9;
            padding: 20px;
            border-right: 1px solid #DFDFDF;
            word-break: break-all;
          }

          .subContent {
            padding: 20px;
            width: 75%;
            word-break: break-all;
          }
        }
      }
    }

    .textInfo {
      margin-top: 10px;
      padding: 20px 0;
      font-size: 12px;
      color: #8b8b8b;
      font-weight: 400;

      p {
        margin-bottom: 5px;
      }

      .address {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
      }
    }
  }
}



//회원관리 표₩
.flex {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto
}

@media (max-width:991.98px) {
  .padding {
    padding: 1.5rem
  }
}

@media (max-width:767.98px) {
  .padding {
    padding: 1rem
  }
}

.padding {
  padding: 5rem
}

.card {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none
}

.pl-3 .px-3 {
  padding-left: 1rem !important
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #d2d2dc;
  border-radius: 0
}

.card .card-title {
  color: #000000;
  margin-bottom: 0.625rem;
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 500
}

.card .card-description {
  margin-bottom: .875rem;
  font-weight: 400;
  color: #76838f
}

p {
  font-size: 0.875rem;
  margin-bottom: .5rem;
  line-height: 1.5rem
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

.table,
.jsgrid .jsgrid-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent
}

.table thead th,
.jsgrid .jsgrid-table thead th {
  border-top: 0;
  border-bottom-width: 1px;
  font-weight: 600;
  font-size: .875rem;
  text-transform: uppercase
}

.table td,
.jsgrid .jsgrid-table td {
  font-size: 0.875rem;
  padding: .875rem 0.9375rem
}

.badge {
  border-radius: 0;
  font-size: 12px;
  line-height: 1;
  padding: .375rem .5625rem;
  font-weight: normal
}

.progress {
  border-radius: 3px;
  height: 8px;
  margin-top: 7px
}

//mail 

.btn-send {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  width: 80%;
  margin-left: 3px;
  background-color: #A4A4A4;
  border-color: #A4A4A4;
}

.btn-send:hover {
  background-color: #2E2E2E;
  border-color: #2E2E2E;
}

.help-block.with-errors {
  color: #ff5050;
  margin-top: 5px
}

.card {
  margin-left: 10px;
  margin-right: 10px
}

.enterprise-input-wrap {
  display: flex;
  width: 100%;
  padding-right: 30%;
  margin-bottom: 20px;
  justify-content: left;
  align-items: center;
}

.enterprise-input-title {
  width: 20%;
  text-align: right;
  font-weight: bold;
  margin-right: 10%;
}

.enterprise-input {
  width: 70%;
}

.react-datepicker-time__input {
  width: 100% !important;
}

.enterprise-member-email-wrap {
  width: 50%;
}

.enterprise-member-action-button-wrap {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.enterprise-member-action-button-master {
  margin-right: 10px;
}

.enterprise-member-action-button-master.selected {
  background-color: #FB7F8F;
  color: #ffffff;
}

.enterprise-member-item {
  display: flex;
  padding: 5px 0px;
  align-items: center;
}
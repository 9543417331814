.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: #f0f0f0;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  &.active {
    border-bottom: 2px solid #007bff;
    background-color: #fff;
  }
}

.manage-favorite {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.manage-favorite__title {
  text-align: center;
  margin-bottom: 20px;
}

.user-id-input,
.manage-favorite__creation {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.manage-favorite__input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.manage-favorite__button {
  padding: 8px 15px;
  margin-right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  &:hover {
    background-color: #0056b3;
  }
}

.favorite-folder {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
  overflow: hidden;
  background-color: white;
}

.favorite-folder-box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    gap: 10px;
  }
}

.favorite-folder__name {
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.favorite-folder__items {
  margin-top: 10px;
  max-height: 400px;
  overflow-y: auto;
}

.favorite-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #eee;
  background-color: skyblue;
}

.favorite-item__id, .favorite-item__registered-at {
  display: block;
}

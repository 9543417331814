.header {
  font-size: 20px;
  margin-bottom: 20px;
}

.row {
  display: flex;
  > span {
    margin-left: 20px;
  }
}
.row2 {
  margin-bottom: 20px;
  .bannerImg {
    display: flex;
    flex-direction: column;
    //
    img {
      &:hover {
        cursor: pointer;
        border: 2px solid palevioletred;
      }
    }
    .logo{
      width: 100px;
    }
  }
}

.bottom_absolute {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 20px;
  height: 55px;
  > button {
    &:hover{
      cursor: pointer;
    }
  }
}

.table {
  border: 1px #a39485 solid;
  font-size: .9em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .25);
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px;
  overflow: hidden;

  thead {
    font-weight: bold;
    color: #fff;

    th {
      text-align: center;
      padding: 1em .5em;
      vertical-align: middle;
      background: #FF6671;
    }

    // th:nth-child(1) {
    //   // border-right: 1px solid #fff;
    //   // border-bottom: 1px solid #fff;
    //   // font-weight: 400;
    //   // color: #fff;
    //   // background: #FF6671;
    //   border: 1px solid #fff;
    // }

    th:nth-child(1) {
      width: 5px;
    }
  }

  tbody {
    tr {
      td {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        background: #fff;
        padding: 1em .5em;
        vertical-align: middle;

        .input {
          width: 70px;
        }

        .btnBox {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 10px;

          .button,
          .isEditingBtn {
            width: 60px;
            height: 28px;
            padding: 5px 12px;
            border: none;
            border-radius: 6px;

            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
          }

          .button {
            background: #FF9B99;
          }

          .isEditingBtn {
            background: #FF0045;
          }
        }
      }

      td:nth-child(1) {
        width: 5px;
      }

      // td:nth-child(1) {
      //   background: #FF6671;
      //   color: #fff;
      // }
    }

    // tr:nth-child(odd) {
    //   td {
    //     background: #FFD2CC;
    //   }
    // }
  }
}

.brand_container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sub_title {
    // margin: 0 0 10px 8px;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
  }
}



// .modalOverlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .modal {
//   background-color: #fff;
//   padding: 20px;
//   border-radius: 10px;
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
//   width: 50%;
//   max-width: 600px;
// }

// .modalHeader {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

// .closeBtn {
//   background-color: transparent;
//   border: none;
//   font-size: 1.5rem;
//   cursor: pointer;
//   padding: 0;
//   margin: 0;
//   outline: none;
// }

// .modalContent {
//   margin-top: 20px;
// }

// .formControl {
//   margin-bottom: 20px;
// }
.title {
  margin: 30px 0px 20px 0px;
}

.input {
  width: 300px;
}

.mailListWrapper {
  margin: 20px;
  min-height: 200px;
}

.loading {
  margin-top: 50px;
  font-size: 30px;
}

.noDataText {
  font-size: 30px;
}

.table {
  background-color: white;
  margin-bottom: 20px;
}

.table td {
  border: 1px solid black;
}

.orderNumTd {
  width: 120px;
}

.resultTd {
  width: 300px;
}

.deleteTd {
  width: 50px;
}

.deleteBtn {
  background-color: white;
  border: none;
  color: red;
  cursor: pointer;
}

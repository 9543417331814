.container {
  margin-bottom: 50px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  max-height: 425px;
  overflow-y: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #d2d2dc;
  border-radius: 0
}

.header {
  position: relative;
  padding: 0 10px;
  display: flex;
  height: 40px;
}

.title {
  color: #000000;
  margin-bottom: 0.625rem;
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 500
}

.description {
  margin-bottom: .875rem;
  font-weight: 400;
  color: #76838f
}

.chooseDate {
  position: absolute;
  top: 3px;
  right: 325px;
  font-size: 0.875rem;
  font-weight: 500
}

.datePickerContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 121px;
}

.datePicker {
  text-align: center;
  width: 100px;
  font-size: 0.8rem;
}

.table tr {
  height: 50px;
  line-height: 110%;
}

.table th, td {
  font-size: 0.875rem !important;
}

.table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
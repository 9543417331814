.container {
  .title {
    width: 100%;
    padding: 0 0 10px 0;
    border-bottom: 2px solid grey;
  
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 200;
    font-size: 36px;
    line-height: 52px;
  
    letter-spacing: -0.6px;
  
    margin: 0 0 20px 0;
  
    color: #000000;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0 0 0;
    gap: 40px;
  
    .brand {
      margin: 0 0 20px 0;
      .sub_title {
        margin: 0 0 10px 8px;
      
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
      }
    }

    .youtuber{
      margin: 0 0 20px 0;
      .sub_title {
        margin: 0 0 10px 8px;
      
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
      }
    }
  }

  

}


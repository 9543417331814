.container {
  margin-bottom: 50px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #d2d2dc;
  border-radius: 0
}

.header {
  position: relative;
  padding: 0 10px;
  display: flex;
  height: 40px;
}

.title {
  color: #000000;
  margin-bottom: 0.625rem;
  text-transform: capitalize;
  font-size: 0.875rem;
  font-weight: 500
}

.description {
  margin-bottom: .875rem;
  font-weight: 400;
  color: #76838f
}

.table tr {
  height: 50px;
  line-height: 110%;
}

.table th {
  font-size: 0.875rem !important;
}

.table td {
  font-size: 0.75rem !important;
}

.table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
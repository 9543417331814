/* .add {
  display: "flex"; width: "100%"; marginBottom: 10;
} */

.label {
  padding: 10px 0;
}

.label p {
  display: inline;
  margin: 0 10px;
  color: black;
  /* text-decoration: bold; */
}

.labelCR1 {
  background: #66DEDA;
}
.labelCR2 {
  background: #33CECA;
}

.labelET {
  background: #FFB400;
}

.labelVC {
  background: #C290FF;
}

/* 92D050, E62984, CA8A54  */
